<script>
import TablePage from '../../../../../components/table_page';

export default {
  name: 'table-component',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/sfa/sfaVisitStepOrderForm/stockAnalysis',
      formConfig: {},
      searchFormData: {
        orderDate: '',
      },
      /** @desc 这个是请求的参数 */
      formData: {
        orderDate: '',
      },
      /** @desc 搜索框绑定数据 */
      /** @todo 自定义列表配置搜索条件，增加字段和控件 */
      configs: {
        formConfig: {
          searchList: [
            {
              isSearch: true,
              search: true,
              field: 'orderDate',
              title: '名称',
              span: 6,
              render: {
                name: 'ElDatePicker',
                props: {
                  placeholder: '请选择年月',
                  type: 'month',
                  value: '',
                  defaultValue: '',
                  valueFormat: 'yyyy-MM',
                  clearable: true,
                },
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    /** @override 重写重置按钮 */
    beforeSearchEvent({ $event }) {
      if ($event.type === 'reset') {
        this.formData.orderDate = '';
        this.searchFormData.orderDate = '';
      }
      return true;
    },
  },
  created() {
    this.getConfigList('sfaCenter_orderManage_terminalPurchaseAnalysis');
  },

};
</script>
